<template>
  <div>
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
    />
    <div class="container py-3" id="checkout-thankyou">
      <countdown :paymentDueDiff="paymentDueDiff" class="mt-4"></countdown>
      <panel-steps
        :content="content"
        :viewMybookingUrl="viewMybookingUrl"
        v-if="content"
      ></panel-steps>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/how-to-pay/partials/countdown';
import PanelDescription from '@/components/how-to-pay/partials/panel-description';
import PanelSteps from '@/components/how-to-pay/partials/panel-steps';
export default {
  name: 'bca',
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'paymentDueDiff',
  ],
  components: {
    PanelDescription,
    Countdown,
    PanelSteps,
  },
  data: () => ({
    content: null,
  }),
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
    }),
  },
  mounted() {
    this.content = {
      tabs: [
        {
          title: 'Via ATM BCA',
          steps: [
            this.$t('booking.guide.bcaAtm.msg1'),
            this.$t('booking.guide.bcaAtm.msg2'),
            this.$t('booking.guide.bcaAtm.msg3'),
            this.$t('booking.guide.bcaAtm.msg4'),
            this.$t('booking.guide.bcaAtm.msg5'),
            this.$t('booking.guide.bcaAtm.msg6'),
          ],
        },
        {
          title: 'Via Klik BCA',
          steps: [
            this.$t('booking.guide.bcaKlik.msg1'),
            this.$t('booking.guide.bcaKlik.msg2'),
            this.$t('booking.guide.bcaKlik.msg3'),
            this.$t('booking.guide.bcaKlik.msg4'),
            this.$t('booking.guide.bcaKlik.msg5'),
          ],
        },
        {
          title: 'Via m-BCA',
          steps: [
            this.$t('booking.guide.bcaM.msg1'),
            this.$t('booking.guide.bcaM.msg2'),
            this.$t('booking.guide.bcaM.msg3'),
            this.$t('booking.guide.bcaM.msg4'),
            this.$t('booking.guide.bcaM.msg5'),
          ],
        },
        // {
        //   title: 'Via Bank Lainnya',
        //   steps: [
        //     this.$t('booking.guide.bcaOthers.msg1'),
        //     this.$t('booking.guide.bcaOthers.msg2'),
        //     this.$t('booking.guide.bcaOthers.msg3'),
        //     this.$t('booking.guide.bcaOthers.msg4'),
        //     this.$t('booking.guide.bcaOthers.msg5'),
        //     this.$t('booking.guide.bcaOthers.msg6'),
        //   ],
        // },
      ],
    };
  },
};
</script>

<style scoped></style>
